(function($) {
  $('.fancybox').fancybox();
  
  $('.datepicker').pickadate({
    min: new Date(),
    format: 'dd-mm-yyyy',
  });
  
  $('#nav-toggle').on('click', function() {
    $('body').toggleClass('nav-in');
  });
})(jQuery);